import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

class About extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid maincontainer">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-12 space">hi</div>
              <div className="col-lg-7 col-sm-12 aboutMe">
                <div className="aboutDiv">
                  <span className="reggie">
                    <h1>
                      <span className="rchild"> Anim.</span> <br />
                      <span className="rchild2">Reginald.</span>
                    </h1>
                  </span>
                  <span className="lordbaddaz">
                    <h1>
                      Alias <br /> <span className="rchild"> Lordbaddaz. </span>
                    </h1>
                  </span>
                </div>
                <ul className="meList">
                  <li className="meList1 animated bounceInLeft">
                    Web Developer.
                  </li>
                  <li className="meList2 animated bounceInRight">Gamer.</li>
                  <li className="meList3">Nutritionist.</li>
                  <li className="meList4 animated bounceInUp">
                    <div className="m4">
                      <span className="boy">Boyfriend.</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <Zoom>
                <div className="col-lg-5 col-sm-12 space2" />
              </Zoom>
              <div className="col-lg-7 col-sm-12 aboutMe2">
                <h1>Who am i?</h1>
                <p>
                  My name is Reginald Paul Tetteh Anim. Born in 1996 in Ghana,
                  went to school in Ghana and still living with my mother in
                  Ghana. I started this blog around May 2018 and this is my
                  first major project. I enjoy a challenge.
                </p>
                <h1>Likes?</h1>
                <p>
                  I like so many things but there is no harm listing a few. I
                  like tech, movies (favourite movie directors are Taika Waititi
                  and Christopher Nolan), programming, building computers,
                  destroying computers,fixing destroyed computers.I do have a
                  knack for fixing things.I just love pixel art, cyberpunk
                  themes, synthwave, anime and dark chocolate.
                </p>
              </div>
            </div>

            <div className="col-lg-12">
              <Fade left delay={2000}>
                <div className="col-lg-5 aboutMe3 ">
                  <h1>
                    WHY THIS <br /> BLOG ?
                  </h1>
                </div>
              </Fade>
              <div className="col-lg-7 space3 ">
                <p>
                  This is a blog i made to review stuff or talk about life or
                  anything interesting in general. <br />
                  It was made with the primary purpose of entertaining the
                  reader, provide relatable content to individuals and also
                  inform people who think like me. I have no specific direction
                  with this blog, but i hope we can all push it forward
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <Zoom left>
              <div className="col-lg-12 col-md-12 col-sm-12 secondContainer">
                <div className="col-lg-6 col-md-6">hi</div>
                <div className="col-lg-6 col-md-6">
                  <h1>Who designed the blog?</h1>
                  <p>
                    I DID.<br /> Special thanks to Anastasia, Jessica, Cyril, My
                    mother{" "}
                  </p>
                  <h1>Will you design a site for me?</h1>
                  <p>You got that paper?</p>
                  <h1>How do i contact you?</h1>
                  <p>Check the navigation bar</p>
                </div>
                All rights reserved
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
