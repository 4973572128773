import { FETCH_USER, LOGGING_USER, CURRENT_USER } from "../actions/fetchUser";

const INITIAL_STATE = { user: null, loading: false };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload };

    case CURRENT_USER:
      return { ...state, user: action.payload, loading: false };

    case LOGGING_USER:
      return { ...state, loading: true };
    default:
      return state;
  }
}
