import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import firebase from 'firebase'
import { Button } from '@mantine/core'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon
} from 'react-share'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import EditableBlock from './EditableBlock'
import { setCaretToEnd } from '../util'
import { fetchDraft } from '../actions/fetchInfo'
import { viewCounter } from '../actions/viewsCounter'

const EditablePage = ({ match, fetchDraft, viewCounter, draft }) => {
  const [blocks, setBlocks] = useState([
    { id: uuid(), html: '', tag: 'p', imageUrl: '', iframeUrl: '' }
  ])
  const [iframe, setIframe] = useState([
    { id: uuid(), html: '', tag: 'p', imageUrl: '', iframeUrl: '' }
  ])
  const [heading, setHeading] = useState([
    { id: uuid(), html: '', tag: 'h1', imageUrl: '', iframeUrl: '' }
  ])
  const [created, setCreated] = useState('')
  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')

  const [currentBlockId, setCurrentBlockId] = useState(null)
  const usePrevious = value => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevBlocks = usePrevious(blocks)
  useEffect(() => {
    const ref = firebase.database().ref()

    const listener = ref
      .child(`draft/${match.params.id}`)
      .on('value', snapshot => {
        const arr = snapshot.val()
        setHeading(arr.info.heading)
        setCreated(arr.info.created)
        setDate(arr.info.date)
        setIframe(arr.iframe)
        setTitle(arr.info.title)
        setImage(arr.info.image)
        if (arr.blocks) {
          setBlocks(arr.blocks)
        }
      })
    return () =>
      firebase
        .database()
        .ref()
        .off('value', listener)
  }, [firebase.db])
  useEffect(() => {
    // If a new block was added, move the caret to it
    if (prevBlocks && prevBlocks.length + 1 === blocks.length) {
      const nextBlockPosition =
        blocks.map(b => b.id).indexOf(currentBlockId) + 1 + 1
      const nextBlock = document.querySelector(
        `[data-position="${nextBlockPosition}"]`
      )
      if (nextBlock) {
        nextBlock.focus()
      }
    }
    // If a block was deleted, move the caret to the end of the last block
    if (prevBlocks && prevBlocks.length - 1 === blocks.length) {
      const lastBlockPosition = prevBlocks
        .map(b => b.id)
        .indexOf(currentBlockId)
      const lastBlock = document.querySelector(
        `[data-position="${lastBlockPosition}"]`
      )
      if (lastBlock) {
        setCaretToEnd(lastBlock)
      }
    }
  }, [blocks, prevBlocks, currentBlockId])

  const updatePageHandler = (setter, state) => updatedBlock => {
    const blocks = state
    const index = blocks.map(b => b.id).indexOf(updatedBlock.id)
    const updatedBlocks = [...blocks]
    updatedBlocks[index] = {
      ...updatedBlocks[index],
      tag: updatedBlock.tag,
      html: updatedBlock.html
    }
    setter(updatedBlocks)
  }

  const addBlockHandler = (setter, state) => currentBlock => {
    setCurrentBlockId(currentBlock.id)
    const blocks = state
    const index = blocks.map(b => b.id).indexOf(currentBlock.id)
    const updatedBlocks = [...blocks]
    const newBlock = {
      id: uuid(),
      html: '',
      tag: 'p',
      imageUrl: '',
      iframeUrl: ''
    }
    updatedBlocks.splice(index + 1, 0, newBlock)
    updatedBlocks[index] = {
      ...updatedBlocks[index],
      tag: currentBlock.tag,
      html: currentBlock.html,
      iframeUrl: currentBlock.iframeUrl,
      imageUrl: currentBlock.imageUrl
    }
    setter(updatedBlocks)
  }

  const deleteBlockHandler = (setter, state, key) => currentBlock => {
    const blocks = state
    if (blocks.length > 1) {
      setCurrentBlockId(currentBlock.id)
      const index = blocks.map(b => b.id).indexOf(currentBlock.id)
      const deletedBlock = blocks[index]
      const updatedBlocks = [...blocks]
      updatedBlocks.splice(index, 1)
      setter(updatedBlocks)
      // If the deleted block was an image block, we have to delete
      // the image file on the server
      firebase
        .database()
        .ref(`draft/${match.params.id}${key}`)
        .set(updatedBlocks)
    }
  }

  const updateBlockHandler = (setter, state, key) => currentBlock => {
    const blocks = state
    const index = blocks.map(b => b.id).indexOf(currentBlock.id)
    const oldBlock = blocks[index]
    const updatedBlocks = [...blocks]
    updatedBlocks[index] = {
      ...updatedBlocks[index],
      tag: currentBlock.tag,
      html: currentBlock.html,
      imageUrl: currentBlock.imageUrl,
      iframeUrl: currentBlock.iframeUrl
    }

    setter(updatedBlocks)

    firebase
      .database()
      .ref(`draft/${match.params.id}${key}`)
      .set(updatedBlocks)
      .catch(err => {
        console.error(err)
      })
    if (oldBlock.imageUrl && oldBlock.imageUrl !== currentBlock.imageUrl) {
      const storageRef = firebase.storage().refFromURL(oldBlock.imageUrl)
      storageRef.delete().then(() => window.alert('image Deleted'))
    }
  }

  const onDragEndHandler = result => {
    const { destination, source } = result
    // If we don't have a destination (due to dropping outside the droppable)
    // or the destination hasn't changed, we change nothing
    if (!destination || destination.index === source.index) {
      return
    }

    const updatedBlocks = [...blocks]
    const removedBlocks = updatedBlocks.splice(source.index - 1, 1)
    updatedBlocks.splice(destination.index - 1, 0, removedBlocks[0])
    setBlocks(updatedBlocks)
  }

  const handlePublish = () => {
    const data = {
      isNewFormat: true,
      blocks,
      iframe,
      info: {
        created,
        date,
        heading,
        image,
        title
      },
      views: 1,
      comments: []
    }
    firebase
      .database()
      .ref(`post`)
      .push(data)
      .then(window.location.assign('https://www.regiew.com'))
  }
  return (
    <div className='Page'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-12 postObject'>
            <Button onClick={handlePublish}>PUBLISH</Button>
            {iframe.map((block, key) => {
              return (
                <EditableBlock
                  noDrag={true}
                  Boxstyle={{
                    width: 'calc(100% - 1rem)',
                    display: 'inline-block'
                  }}
                  key={block.id}
                  id={block.id}
                  tag={block.tag}
                  imageUrl={block.imageUrl}
                  iframeUrl={block.iframeUrl}
                  html={block.html}
                  position={iframe.map(b => b.id).indexOf(iframe.id) + 1}
                  updateBlock={updateBlockHandler(setIframe, iframe, '/iframe')}
                  updatePage={updatePageHandler(setIframe, iframe)}
                  addBlock={addBlockHandler(setIframe, iframe)}
                  deleteBlock={deleteBlockHandler(setIframe, iframe, '/iframe')}
                />
              )
            })}
          </div>
          <div className='col-lg-6 col-md-6 postObjectText'>
            {heading.map((block, key) => {
              return (
                <EditableBlock
                  Boxstyle={{
                    width: 'calc(auto - 1rem)',
                    display: 'inline-block'
                  }}
                  noDrag={true}
                  key={block.id}
                  id={block.id}
                  tag={block.tag}
                  imageUrl={block.imageUrl}
                  iframeUrl={block.iframeUrl}
                  html={block.html}
                  position={heading.map(b => b.id).indexOf(heading.id) + 1}
                  updateBlock={updateBlockHandler(
                    setHeading,
                    heading,
                    '/info/heading'
                  )}
                  updatePage={updatePageHandler(setHeading, heading)}
                  addBlock={addBlockHandler(setHeading, heading)}
                  deleteBlock={deleteBlockHandler(
                    setHeading,
                    heading,
                    '/info/heading'
                  )}
                />
              )
            })}
            <div className='shares'>
              <FacebookShareButton
                className='share-buttons'
                url={'regiew.com/post/' + match.params.id}
              >
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <WhatsappShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + match.params.id}
              >
                <WhatsappIcon size={32} />
              </WhatsappShareButton>
              <TwitterShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + match.params.id}
              >
                <TwitterIcon size={32} />
              </TwitterShareButton>
              <RedditShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + match.params.id}
              >
                <RedditIcon size={32} />
              </RedditShareButton>
            </div>
            <i className='fas fa-user-secret'>
              <span className='dateText'>{created}</span>
            </i>
            <br />
            <i className='fas fa-eye'>
              <span className='dateText'>2</span>
            </i>
            . <br />
            <i className='far fa-calendar-alt'>
              <span className='dateText'>{date}</span>
            </i>
            <div className='paragraphs container'>
              <div className='row'>
                <div className='col-lg-12 textContainer'>
                  <DragDropContext onDragEnd={onDragEndHandler}>
                    <Droppable droppableId={'123'}>
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {blocks.map(block => (
                            <EditableBlock
                              key={block.id}
                              position={
                                blocks.map(b => b.id).indexOf(block.id) + 1
                              }
                              id={block.id}
                              tag={block.tag}
                              html={block.html}
                              imageUrl={block.imageUrl}
                              iframeUrl={block.iframeUrl}
                              updatePage={updatePageHandler(setBlocks, blocks)}
                              addBlock={addBlockHandler(setBlocks, blocks)}
                              deleteBlock={deleteBlockHandler(
                                setBlocks,
                                blocks,
                                '/blocks'
                              )}
                              updateBlock={updateBlockHandler(
                                setBlocks,
                                blocks,
                                '/blocks'
                              )}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              {/* <div className='comments'>
                      <h1>COMMENTS</h1> */}
              {/* {renderComments(post.comments)} */}
              {/* <Comments id={'123'} /> */}
              {/* </div> */}
            </div>
          </div>
          <div className='col-lg-2 col-sm-12 postEnd'>
            <p>Still Under Construction </p>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return { draft: state.drafts.draft }
}

export default connect(mapStateToProps, { fetchDraft, viewCounter })(
  EditablePage
)
