import firebase from "firebase";
export const FETCH_USER = "FETCH_USER";

export const CURRENT_USER = "CURRENT_USER";

export const LOGGING_USER = "LOGGING_USER";

// export const fetchUser = () => {
//   return dispatch => {
//     const provider = new firebase.auth.GoogleAuthProvider();
//     firebase
//       .auth()
//       .signInWithRedirect(provider)
//       .then(function(result) {
//         // var user = result.user;

//         dispatch({
//           type: FETCH_USER,
//           payload: result.user
//         });
//       })

//       .catch(function(error) {
//         // Handle Errors here.
//         // var errorCode = error.code;
//         // var errorMessage = error.message;
//         // The email of the user's account used.
//         alert(error.message);
//         // var email = error.email;
//         // The firebase.auth.AuthCredential type that was used.
//         // var credential = error.credential;

//         // ...
//       });
//   };
// };

export const fetchUser = () => {
  return dispatch => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithRedirect(provider)
      .then(function(result) {
        // var user = result.user;

        dispatch({
          type: FETCH_USER,
          payload: result.user
        });
      })

      .catch(function(error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // The email of the user's account used.
        alert(error.message);
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;

        // ...
      });
  };
};

// export const fetchUserFacebook = () => {
//   return dispatch => {
//     const provider = new firebase.auth.FacebookAuthProvider();
//     firebase
//       .auth()
//       .signInWithPopup(provider)

//       .then(function(result) {
//         dispatch({
//           type: FETCH_USER,
//           payload: result.user
//         });
//       })
//       .catch(function(error) {
//         console.log(error.code);
//         alert(error.message);
//       });
//   };
// };

export const currentUser = () => {
  return dispatch => {
    dispatch({
      type: "LOGGING_USER"
    });
    firebase.auth().onAuthStateChanged(user => {
      dispatch({
        type: CURRENT_USER,
        payload: user
      });
    });
  };
};
