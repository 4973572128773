import { FETCH_DRAFTS, FETCH_DRAFT } from '../actions/fetchInfo'
const INITIAL_STATE = { all: [], post: null }

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DRAFTS:
      return { ...state, all: action.payload }

    case FETCH_DRAFT:
      return { ...state, draft: action.payload }

    default:
      return state
  }
}
