import React, { Component } from 'react'
import { currentUser } from '../actions/fetchUser'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
// import Button from './Button'
import './button.scss'
import firebase from 'firebase'

class Comments extends Component {
  componentWillMount () {
    this.props.currentUser()
  }

  state = {
    comment: '',
    commenterr: false,
    helpertext: ''
  }

  change = e => {
    this.setState({
      [e.target.name]: e.target.value,
      commenterr: false,
      helpertext: ''
    })
  }

  handleErrors = e => {
    if (this.state.comment === '') {
      this.setState({
        commenterr: true,
        helpertext: `Your comment cannot be empty ${this.props.user.user.displayName}`
      })
    } else if (this.state.comment.length > 250) {
      this.setState({
        commenterr: true,
        helpertext: `Your comment is over 9000 ${this.props.user.user.displayName}`
      })
    } else {
      this.submit(e)
    }
  }

  handleAnonErrors = e => {
    if (this.state.comment === '') {
      this.setState({
        commenterr: true,
        helpertext: `Your comment cannot be empty ${this.props.user.user.displayName}`
      })
    } else if (this.state.comment.length > 250) {
      this.setState({
        commenterr: true,
        helpertext: `Your comment is over 9000 ${this.props.user.user.displayName}`
      })
    } else {
      this.Anonsubmit(e)
    }
  }

  Anonsubmit = e => {
    e.preventDefault()
    const d = new Date()

    // if(!this.props.user.user.displayName) {
    //   this.props.user.user.displayName = "anonymous"
    // }
    const comment = {
      text: this.state.comment,
      name: 'Anonymous dude',
      date: d.toLocaleString()
    }
    // console.log(comment)
    firebase
      .database()
      .ref(`post/${this.props.id}/comments`)
      .push(comment)
    this.setState({ comment: '' })
  }

  submit = e => {
    e.preventDefault()
    const d = new Date()

    // if(!this.props.user.user.displayName) {
    //   this.props.user.user.displayName = "anonymous"
    // }
    const comment = {
      text: this.state.comment,
      name: this.props.user.user.displayName,
      date: d.toLocaleString()
    }
    // console.log(comment)
    firebase
      .database()
      .ref(`post/${this.props.id}/comments`)
      .push(comment)
      .then(() => window.location.reload())
    this.setState({ comment: '' })
  }

  renderComment () {
    const { user, loading } = this.props.user
    if (loading) {
      return
    } else if (user == null) {
      return (
        <div>
          <p>Please Login to comment! or comment as anonymous</p> <br />
          <TextField
            error={this.state.commenterr}
            helperText={this.state.helpertext}
            id='standard-textarea'
            name='comment'
            label='Comment'
            placeholder='Comment'
            multiline
            variant='outlined'
            value={this.state.comment}
            onChange={e => this.change(e)}
          />
          <button
            className='btn draw-border'
            onClick={e => this.handleAnonErrors(e)}
          >
            Post
          </button>
        </div>
      )
    } else if (user) {
      return (
        <div>
          <br />
          <p>Commenting as {user.displayName}</p>

          <TextField
            error={this.state.commenterr}
            helperText={this.state.helpertext}
            id='standard-textarea'
            name='comment'
            label='Comment'
            placeholder='Comment'
            multiline
            variant='outlined'
            value={this.state.comment}
            onChange={e => this.change(e)}
          />
          <button
            className='btn draw-border'
            onClick={e => this.handleErrors(e)}
          >
            Post
          </button>
        </div>
      )
    }
  }
  render () {
    return <div>{this.renderComment()}</div>
  }
}

function mapStateToProps (state) {
  return { user: state.user }
}

export default connect(mapStateToProps, { currentUser })(Comments)
