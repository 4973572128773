import React, { Component } from 'react'

import { BarLoader } from 'react-spinners'
import { connect } from 'react-redux'
import { type, equals } from 'ramda'

import Img from 'react-image'
import { Link } from 'react-router-dom'
import { XMasonry, XBlock } from 'react-xmasonry/dist/index.js'
import { fetchposts } from '../actions/fetchInfo'
import Loading from './Loading'

// const posts = [
//   {
//     id: "list1",
//     heading: "A blog post",
//     title: "The spectacle before us was indeed sublime.",
//     image: "/images/0.jpg",
//     created: "Reginald Anim",
//     date: ""
//     // iframeSrc= "",
//     // iframeH1 = "",
//     // paragraph1 = "",
//     // paragraph1Position ="",
//     // figure1Src = "",
//     // figure1Position = "",
//     // paragraph2 = "",
//     // paragraph2Position = "",
//     // figure2Src = "",
//     // figure2Position = "",
//     // paragraph3 = "",
//     // paragraph3Position = "",
//     // figure3Src = "",
//     // figure3Position = "",
//     // paragraph4 = "",
//     // paragraph4Position = "",
//     // figure4Src = "",
//     // figure4Position = "",
//   },
//   {
//     id: "list2",
//     heading: "A nothing",
//     title: "We were once in love, you broke my heart and never came back",
//     image: "/images/5.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list3",
//     heading: "Livestream \n Uploaded",
//     title: "Mike is the Executive Creative Director ",
//     image: "/images/3.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list4",
//     heading: "Goukakyu no jutsu",
//     title: " IBM Brand Experience & Design—a team overseeing the exp",
//     image: "/images/4.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list5",
//     heading: "A blog post",
//     title:
//       "To embed your selected fonts into a webpage, copy this code into the <head> of your HTML document.",
//     image: "/images/5.png",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list6",
//     heading: "A something",
//     title:
//       "Asaana is processed on a small scale basis, the method of processing Asaana is simple and quick and can easily be done in the household.",
//     image: "/images/7.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list7",
//     heading: "HADOUKEN",
//     title:
//       "Asaana is processed on a small scale basis, the method of processing Asaana is simple and quick and can easily be done in the household.",
//     image: "/images/8.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list8",
//     heading: "Bellerin is an idiot",
//     title: "A shining crescent far beneath the flying vessel.",
//     image: "/images/6.jpg",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list9",
//     heading: "A fucking gtx titan xp",
//     title:
//       "Asaana is processed on a small scale basis, the method of processing Asaana is simple and quick and can easily be done in the household.",
//     image: "/images/7.png",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list10",
//     heading: "hmm",
//     title: `I watched the storm, so beautiful yet terrific.`,
//     image: "/images/7.gif",
//     created: "Reginald Anim"
//   },
//   {
//     id: "list11",
//     heading: "ANAS BABY",
//     title:
//       "Asaana is processed on a small scale basis, the method of processing Asaana is simple and quick and can easily be done in the household.",
//     image: "/images/yo2.gif",
//     created: "Reginald Anim"
//   }
// ];

class Landing extends Component {
  componentWillMount () {
    // firebase
    //   .database()
    //   .ref()
    //   .child("post")

    //   .on("value", snapshot => {
    //     const arr = _(snapshot.val()) //wrap object so that you can chain lodash methods
    //       .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
    //       .values() //get the values of the result
    //       .value();
    //     console.log(arr);
    //   });
    // this.setState({ loading: true });
    this.props.fetchposts()
  }

  render () {
    if (this.props.posts) {
      var images = this.props.posts.map(post => {
        return (
          <XBlock key={post.id}>
            <Link to={'/post/' + post.id}>
              <li className='grid hvr-shrink idk' key={post.id}>
                <div className='grid-item ' id='grid-item'>
                  <Img
                    src={post.info.image}
                    id={post.id}
                    loader={
                      <BarLoader
                        color={'#80DEEA'}
                        loading={true}
                        width={225}
                        decode={false}
                      />
                    }
                  />

                  {/* TEXT */}
                  <h1 className='heading'>
                    {equals(type(post.info.heading), type([]))
                      ? post.info.heading[0].html
                      : post.info.heading}
                  </h1>
                  <h3 className='texts title'>
                    {equals(type(post.info.title), type([]))
                      ? post.info.title[0].html
                      : post.info.title}
                  </h3>
                  <p className='texts creator'>
                    <i className='fas fa-user-secret'>
                      <span className='dateText'>{post.info.created}</span>
                    </i>
                    . <br />
                    <i className='far fa-calendar-alt'>
                      <span className='dateText'>{post.info.date}</span>
                    </i>
                  </p>

                  <div className='Read hvr-wobble-bottom'>READ MORE</div>
                </div>
              </li>
            </Link>
          </XBlock>
        )
      })
    } else {
      return (
        <div>
          {/* <Loading />epilepsy */}
          <div className='container-fluid '>
            <div className='row' id='top-row'>
              <div className='col-md-4 col-sm-12' id='myblogWarning'>
                <span className='blogText '>
                  <h1>WARNING</h1>
                  <p>
                    This website contains flashing images and may not be
                    suitable for photosensitive epilepsy
                  </p>
                </span>
              </div>
              <div className='col-md-8 ' id='myblog2'>
                <Loading />
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='container-fluid '>
        <div className='row' id='top-row'>
          <div className='col-md-4 col-sm-12 bounceInLeft animated' id='myblog'>
            <span className='blogText '>
              <h1>
                SITE
                <br />
                <span className='blog'>UPDATED</span>
              </h1>
            </span>
          </div>
          <div className='col-md-8 ' id='myblog2'>
            <XMasonry
              responsive={true}
              updateOnFontLoad={true}
              center={false}
              targetBlockWidth={300}
              smartUpdate={true}
            >
              {images}
            </XMasonry>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  if (state.posts.all.length > 0) {
    return {
      posts: state.posts.all
    }
  } else return { posts: null }
}
export default connect(mapStateToProps, { fetchposts })(Landing)
