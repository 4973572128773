import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { currentUser } from '../actions/fetchUser'
import firebase from 'firebase/app'
import 'firebase/database'
import Loading from './Loading'

class BlogForm extends Component {
  componentWillMount () {
    this.props.currentUser()
  }

  state = {
    admin: null
  }

  searchAdmin (user) {
    firebase
      .database()
      .ref('/users/' + user.uid)
      .once('value', snapshot => {
        const arr = snapshot.val().isAdmin
        this.setState({ admin: arr })
      })
  }

  renderAdmin () {
    const { user, loading } = this.props.user
    if (user) {
      this.searchAdmin(user)
      return <>{this.props.children}</>
    } else if (loading) {
      return <Loading />
    } else {
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      )
    }
  }

  render () {
    return <div>{this.renderAdmin()}</div>
  }
}

function mapStatetoProps (state) {
  return {
    categories: state.categories,
    user: state.user
  }
}

export default connect(mapStatetoProps, { currentUser })(BlogForm)
