import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import firebase from 'firebase/app'

import Auth from './Auth'
import Blogform from './BlogForm'
import Landing from './Landing'
import Navbar from './Navbar'
import BottomBar from './BottomBar'
import BlogBlocks from './BlogBlocks'
import CreateDraft from './CreateDraft'
import PostItem from './PostItem'
import About from './About'
import Protected from './Protected'

const CreateItem = () => (
  <Protected>
    <CreateDraft />
  </Protected>
)
// const EditBlocks = () => (
//   <Protected>
//     <BlogBlocks />
//   </Protected>
// )

class App extends Component {
  state = {
    loggedIn: false
  }
  componentWillMount () {
    firebase.initializeApp({
      apiKey: 'AIzaSyCykx_ys9YGaB8FFUbGHDWbFoEA1F8L6sk',
      authDomain: 'regiews-70fa8.firebaseapp.com',
      databaseURL: 'https://regiews-70fa8.firebaseio.com',
      projectId: 'regiews-70fa8',
      storageBucket: 'gs://regiews-70fa8.appspot.com',
      messagingSenderId: '816594668901'
    })
  }

  render () {
    return (
      // <MuiThemeProvider>
      <BrowserRouter>
        <div className='Parent'>
          <Route component={Navbar} />
          <Route component={BottomBar} />
          <Route exact path='/' component={Landing} />
          <Route exact path='/login' component={Auth} />
          <Route path='/post/:id' component={PostItem} />
          <Route exact path='/post-item' component={Blogform} />
          <Route exact path='/create-item' component={CreateItem} />
          <Route exact path='/draft/:id' component={BlogBlocks} />
          <Route exact path='/about' component={About} />
        </div>
      </BrowserRouter>
    )
  }
}

export default App
