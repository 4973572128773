export const getCaretCoordinates = (fromStart = true) => {
  let x, y
  const selection = window.getSelection()
  console.log(selection)
  if (selection.rangeCount !== 0) {
    const range = selection.getRangeAt(0).cloneRange()
    range.collapse(fromStart ? true : false)
    const rect = range.getClientRects()[0]
    if (rect) {
      x = rect.left
      y = rect.top
    }
  }
  return { x, y }
}

export const setCaretToEnd = element => {
  const range = document.createRange()
  const selection = window.getSelection()
  range.selectNodeContents(element)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
  element.focus()
}
