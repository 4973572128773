import React, { Component } from 'react'
import { matchSorter } from 'match-sorter'
import './selectMenu.scss'

// const MENU_WIDTH = 360
const allowedTags = [
  {
    id: 'page-title',
    tag: 'h1',
    label: 'Page Title'
  },
  {
    id: 'heading',
    tag: 'h2',
    label: 'Heading'
  },
  {
    id: 'subheading',
    tag: 'h3',
    label: 'Subheading'
  },
  {
    id: 'paragraph',
    tag: 'p',
    label: 'Paragraph'
  },
  {
    id: 'image',
    tag: 'img',
    label: 'Image'
  },
  {
    id: 'iframe',
    tag: 'iframe',
    label: 'Iframe'
  }
]

class SelectMenu extends Component {
  constructor (props) {
    super(props)
    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.state = {
      command: '',
      items: allowedTags,
      selectedItem: 0
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.keyDownHandler)
  }

  componentDidUpdate (prevProps, prevState) {
    const command = this.state.command
    if (prevState.command !== command) {
      const items = matchSorter(allowedTags, command, { keys: ['tag'] })
      this.setState({ items: items })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.keyDownHandler)
  }

  keyDownHandler (e) {
    const items = this.state.items
    const selected = this.state.selectedItem
    const command = this.state.command

    switch (e.key) {
      case 'Enter':
        e.preventDefault()
        this.props.onSelect(items[selected].tag)
        break
      case 'Backspace':
        if (!command) this.props.close()
        this.setState({ command: command.substring(0, command.length - 1) })
        break
      case 'ArrowUp':
        e.preventDefault()
        const prevSelected = selected === 0 ? items.length - 1 : selected - 1
        this.setState({ selectedItem: prevSelected })
        break
      case 'ArrowDown':
      case 'Tab':
        e.preventDefault()
        const nextSelected = selected === items.length - 1 ? 0 : selected + 1
        this.setState({ selectedItem: nextSelected })
        break
      default:
        this.setState({ command: this.state.command + e.key })
        break
    }
  }

  render () {
    // const positionAttributes = { top: `${y - 500}px`, left: `${x}px` }
    // console.log(positionAttributes)

    return (
      <div
        className='SelectMenu'
        style={{
          //   ...positionAttributes,
          width: 'fit-content',
          backgroundColor: '#FBFBFA',
          position: 'absolute',
          display: 'inline-block',
          boxShadow:
            'rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px',
          zIndex: 11,
          //   display: 'flex',
          //   flexDirection: 'column',
          borderRadius: '4px'
        }}
      >
        <div className='Items' style={{ fontFamily: 'Fira Sans' }}>
          {this.state.items.map((item, key) => {
            const selectedItem = this.state.selectedItem
            const isSelected = this.state.items.indexOf(item) === selectedItem
            return (
              <div
                id='Menu'
                className={isSelected ? 'selected' : null}
                key={key}
                role='button'
                tabIndex='0'
                onClick={() => this.props.onSelect(item.tag)}
              >
                <h5
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingBottom: '5px',
                    textTransform: 'uppercase'
                  }}
                >
                  {item.label}
                </h5>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default SelectMenu
