import firebase from "firebase";

export const GET_VIEWS = "GET_VIEWS";

export const viewCounter = id => {
    return dispatch => {
      firebase
        .database()
        .ref()
        .child(`post/${id}`)
        .once("value", snapshot => {
          const viewcounter = snapshot.val().views + 1
        //   console.log(viewcounter)
      
          firebase
        .database()
        .ref(`post/${id}/`)
        .update({views: viewcounter})
        });
    };
  };

