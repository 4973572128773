import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import firebase from 'firebase'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon
} from 'react-share'

import Comments from './Comments'
import _ from 'lodash'
import { connect } from 'react-redux'
import EditableBlock from './EditableBlock'
import { fetchDraft } from '../actions/fetchInfo'
import { viewCounter } from '../actions/viewsCounter'

const EditablePage = ({ itemId }) => {
  const [post, setPost] = useState({})
  const [blocks, setBlocks] = useState([
    { id: uuid(), html: '', tag: 'p', imageUrl: '', iframeUrl: '' }
  ])
  const [iframe, setIframe] = useState([
    { id: uuid(), html: '', tag: 'p', imageUrl: '', iframeUrl: '' }
  ])
  const [heading, setHeading] = useState([
    { id: uuid(), html: '', tag: 'h1', imageUrl: '', iframeUrl: '' }
  ])
  const [created, setCreated] = useState('')
  const [date, setDate] = useState('')

  useEffect(() => {
    const ref = firebase.database().ref()
    const listener = ref.child(`post/${itemId}`).once('value', snapshot => {
      const arr = snapshot.val()
      setPost(arr)
      setHeading(arr.info.heading)
      setCreated(arr.info.created)
      setDate(arr.info.date)
      setIframe(arr.iframe)
      if (arr.blocks) {
        setBlocks(arr.blocks)
      }
    })
    return () =>
      firebase
        .database()
        .ref()
        .off('value', listener)
  }, [firebase.db])
  const renderComments = comment => {
    if (!comment) {
      return <h2>NO COMMENTS ON THIS POST :(</h2>
    } else {
      let commentsarr = _(comment)
        .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
        .values() //get the values of the result
        .value()

      const stuff = commentsarr.map(comment => {
        return (
          <div key={comment.date} className='comment-box'>
            {' '}
            <p>
              <span className='user'>{comment.name}</span>
              {comment.date}
            </p>
            <p>{comment.text}</p>{' '}
          </div>
        )
      })
      return stuff
    }
  }
  return (
    <div className='Page'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-12 postObject'>
            {iframe.map((block, key) => {
              return (
                <EditableBlock
                  disabled={true}
                  noDrag={true}
                  Boxstyle={{
                    width: 'calc(100% - 1rem)',
                    display: 'inline-block'
                  }}
                  key={block.id}
                  id={block.id}
                  tag={block.tag}
                  imageUrl={block.imageUrl}
                  iframeUrl={block.iframeUrl}
                  html={block.html}
                  position={iframe.map(b => b.id).indexOf(iframe.id) + 1}
                />
              )
            })}
          </div>
          <div className='col-lg-6 col-md-6 postObjectText'>
            {heading.map((block, key) => {
              return (
                <EditableBlock
                  Boxstyle={{
                    width: 'calc(auto - 1rem)',
                    display: 'inline-block'
                  }}
                  disabled={true}
                  noDrag={true}
                  key={block.id}
                  id={block.id}
                  tag={block.tag}
                  imageUrl={block.imageUrl}
                  iframeUrl={block.iframeUrl}
                  html={block.html}
                  position={heading.map(b => b.id).indexOf(heading.id) + 1}
                />
              )
            })}
            <div className='shares'>
              <FacebookShareButton
                className='share-buttons'
                url={'regiew.com/post/' + itemId}
              >
                <FacebookIcon size={32} />
              </FacebookShareButton>
              <WhatsappShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + itemId}
              >
                <WhatsappIcon size={32} />
              </WhatsappShareButton>
              <TwitterShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + itemId}
              >
                <TwitterIcon size={32} />
              </TwitterShareButton>
              <RedditShareButton
                className='share-buttons'
                url={'https://regiew.com/post/' + itemId}
              >
                <RedditIcon size={32} />
              </RedditShareButton>
            </div>
            <i className='fas fa-user-secret'>
              <span className='dateText'>{created}</span>
            </i>
            <br />
            <i className='fas fa-eye'>
              <span className='dateText'>{post.views}</span>
            </i>
            . <br />
            <i className='far fa-calendar-alt'>
              <span className='dateText'>{date}</span>
            </i>
            <div className='paragraphs container'>
              <div className='row'>
                <div className='col-lg-12 textContainer'>
                  {blocks.map(block => (
                    <EditableBlock
                      noDrag={true}
                      disabled={true}
                      key={block.id}
                      position={blocks.map(b => b.id).indexOf(block.id) + 1}
                      id={block.id}
                      tag={block.tag}
                      html={block.html}
                      imageUrl={block.imageUrl}
                      iframeUrl={block.iframeUrl}
                    />
                  ))}
                </div>
              </div>
              <div className='comments'>
                <h1>COMMENTS</h1>
                {renderComments(post.comments)}
                <Comments id={itemId} />
              </div>
            </div>
          </div>
          <div className='col-lg-2 col-sm-12 postEnd'>
            <p>Still Under Construction </p>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return { draft: state.drafts.draft }
}

export default connect(mapStateToProps, { fetchDraft, viewCounter })(
  EditablePage
)
