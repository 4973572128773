import React from 'react'
import { Input } from '@mantine/core'

const IframeContent = ({
  tag,
  id,
  onChange,
  onSubmit,
  iframeUrl,
  iframeStyle
}) => {
  return (
    <>
      {!iframeUrl && (
        <>
          <label htmlFor={`${id}_TextInput`} className='fileInputLabel'>
            Please paste embed link and press enter
          </label>
          <form onSubmit={onSubmit}>
            <Input
              placeholder='Embed Link'
              radius='lg'
              size='xs'
              onChange={onChange}
              id={`${id}_TextInput`}
              name={tag}
            />
          </form>
        </>
      )}
      {iframeUrl && (
        <iframe
          style={{
            ...iframeStyle,
            display: 'inline-block',
            width: 'calc(100% - 1rem)'
          }}
          src={iframeUrl}
        />
      )}
    </>
  )
}

export default IframeContent
