import React, { Component } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon
} from 'react-share'
import { connect } from 'react-redux'

import Comments from './Comments'

import { fetchpost } from '../actions/fetchInfo'
import { viewCounter } from '../actions/viewsCounter'
import Loading from './Loader'
import NewPostItem from './NewPostItem'
import Zoom from 'react-reveal/Zoom'
import _ from 'lodash'

class PostItem extends Component {
  componentWillMount () {
    this.props.fetchpost(this.props.match.params.id)
    this.props.viewCounter(this.props.match.params.id)
  }

  render () {
    const renderComments = comment => {
      if (!comment) {
        return <h2>NO COMMENTS ON THIS POST :(</h2>
      } else {
        let commentsarr = _(comment)
          .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
          .values() //get the values of the result
          .value()

        const stuff = commentsarr.map(comment => {
          return (
            <div key={comment.date} className='comment-box'>
              {' '}
              <p>
                <span className='user'>{comment.name}</span>
                {comment.date}
              </p>
              <p>{comment.text}</p>{' '}
            </div>
          )
        })
        return stuff
      }
    }
    const { post } = this.props
    if (!this.props.post) {
      return <Loading />
    } else if (this.props.post.isNewFormat)
      return <NewPostItem itemId={this.props.match.params.id} />
    else {
      // console.log(post)
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12 postObject'>
              <iframe scrolling='no' src={post.iframeSrc} title='iframe' />
            </div>
            <div className='col-lg-6 col-md-6 postObjectText'>
              <h1>{post.info.heading}</h1>
              <div className='shares'>
                <FacebookShareButton
                  className='share-buttons'
                  url={'regiew.com/post/' + this.props.match.params.id}
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <WhatsappShareButton
                  className='share-buttons'
                  url={'https://regiew.com/post/' + this.props.match.params.id}
                >
                  <WhatsappIcon size={32} />
                </WhatsappShareButton>
                <TwitterShareButton
                  className='share-buttons'
                  url={'https://regiew.com/post/' + this.props.match.params.id}
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
                <RedditShareButton
                  className='share-buttons'
                  url={'https://regiew.com/post/' + this.props.match.params.id}
                >
                  <RedditIcon size={32} />
                </RedditShareButton>
              </div>
              <i className='fas fa-user-secret'>
                <span className='dateText'>{post.info.created}</span>
              </i>
              <br />
              <i className='fas fa-eye'>
                <span className='dateText'>{post.views}</span>
              </i>
              . <br />
              <i className='far fa-calendar-alt'>
                <span className='dateText'>{post.info.date}</span>
              </i>
              <div className='paragraphs container'>
                <div className='row'>
                  <div className='col-lg-12 textContainer'>
                    <div className={post.figure1Position}>
                      <figure id='well' className='figAlignLeft'>
                        <img src={post.figure1Src} alt='broken ' />
                      </figure>
                    </div>
                    <p>{post.paragraph1}</p>
                  </div>
                  <div className='col-lg-12 textContainer'>
                    <div className={post.figure2Position}>
                      <figure id='well' className='figAlignLeft'>
                        <img src={post.figure2Src} alt='broken ' />
                      </figure>
                    </div>
                    <p>{post.paragraph2}</p>
                  </div>
                  <Zoom>
                    <div className='col-lg-12 textContainer'>
                      <div className={post.figure3Position}>
                        <figure id='well' className='figAlignLeft'>
                          <img src={post.figure3Src} alt='broken ' />
                        </figure>
                      </div>
                      <p>{post.paragraph3}</p>
                    </div>
                  </Zoom>
                  <div className='col-lg-12 textContainer'>
                    <div className={post.figure4Position}>
                      <figure id='well' className='figAlignLeft'>
                        <img src={post.figure4Src} alt='broken ' />
                      </figure>
                    </div>
                    <p>{post.paragraph4}</p>
                  </div>
                </div>
                <div className='comments'>
                  <h1>COMMENTS</h1>
                  {renderComments(post.comments)}
                  <Comments id={this.props.match.params.id} />
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-sm-12 postEnd'>
              <p>Still Under Construction </p>
            </div>
          </div>
        </div>
      )
    }
  }
}
function mapStateToProps (state) {
  return { post: state.posts.post }
}

export default connect(mapStateToProps, { fetchpost, viewCounter })(PostItem)
