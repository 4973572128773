import { GET_CATEGORIES } from "../actions/getCategories";

const INITIAL_STATE = { category: [] };
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, category: action.payload };

    default:
      return state;
  }
}
