import React, { Component } from 'react'
import firebase from 'firebase'
import { equals, and, not } from 'ramda'
import ContentEditable from 'react-contenteditable'
import SelectMenu from './SelectMenu'
import ActionMenu from './ActionMenu'
import { Draggable } from 'react-beautiful-dnd'
import './editableBlock.scss'
import ImageContent from './tags/ImageContent'
import IframeContent from './tags/IframeContent'
import DragHandleIcon from './draggable.svg'
import { getCaretCoordinates } from '../util'

const CMD_KEY = '/'

class EditableBlock extends Component {
  myRef = React.createRef()
  constructor (props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this)
    this.contentEditable = React.createRef()
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleIframeSrc = this.handleIframeSrc.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this)
    this.openSelectMenuHandler = this.openSelectMenuHandler.bind(this)
    this.closeSelectMenuHandler = this.closeSelectMenuHandler.bind(this)
    this.handleDragHandleClick = this.handleDragHandleClick.bind(this)
    this.closeActionMenu = this.closeActionMenu.bind(this)
    this.closeTagSelectorMenu = this.closeTagSelectorMenu.bind(this)
    this.tagSelectionHandler = this.tagSelectionHandler.bind(this)
    this.handleImageUpload = this.handleImageUpload.bind(this)
    this.openActionMenu = this.openActionMenu.bind(this)
    this.calculateActionMenuPosition = this.calculateActionMenuPosition.bind(
      this
    )
    this.calculateTagSelectorMenuPosition = this.calculateTagSelectorMenuPosition.bind(
      this
    )
    this.fileInput = null
    this.state = {
      htmlBackup: null,
      html: '',
      tag: 'p',
      imageUrl: '',
      iframeUrl: '',
      isTyping: false,
      iframe: '',
      previousKey: '',
      selectMenuIsOpen: false,
      selectMenuPosition: {
        x: null,
        y: null
      },
      actionMenuOpen: false,
      actionMenuPosition: {
        x: null,
        y: null
      }
    }
  }

  componentDidMount () {
    this.setState({
      html: this.props.html,
      tag: this.props.tag,
      imageUrl: this.props.imageUrl,
      iframeUrl: this.props.iframeUrl
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const stoppedTyping = prevState.isTyping && !this.state.isTyping
    const htmlChanged = this.props.html !== this.state.html
    const tagChanged = this.props.tag !== this.state.tag
    const imageChanged = this.props.imageUrl !== this.state.imageUrl
    const iframeChanged = this.props.iframeUrl !== this.state.iframeUrl
    if (
      (stoppedTyping && htmlChanged) ||
      tagChanged ||
      imageChanged ||
      iframeChanged
    ) {
      this.props.updateBlock({
        id: this.props.id,
        html: this.state.html,
        tag: this.state.tag,
        imageUrl: this.state.imageUrl,
        iframeUrl: this.state.iframeUrl
      })
    }
  }

  calculateTagSelectorMenuPosition (initiator) {
    switch (initiator) {
      case 'KEY_CMD':
        const { x: caretLeft, y: caretTop } = getCaretCoordinates(true)
        return { x: caretLeft, y: caretTop }
      case 'ACTION_MENU':
        const { x: actionX, y: actionY } = this.state.actionMenuPosition
        return { x: actionX - 40, y: actionY }
      default:
        return { x: null, y: null }
    }
  }

  calculateActionMenuPosition (parent, initiator) {
    switch (initiator) {
      case 'TEXT_SELECTION':
        const { x: endX } = getCaretCoordinates(false) // fromEnd
        const { x: startX, y: startY } = getCaretCoordinates(true) // fromStart
        const middleX = startX + (endX - startX) / 2
        return { x: middleX, y: startY }
      case 'DRAG_HANDLE_CLICK':
        const x = parent.offsetLeft - parent.scrollLeft + parent.clientLeft - 90
        const y = parent.offsetTop - parent.scrollTop + parent.clientTop + 35
        return { x: x, y: y }
      default:
        return { x: null, y: null }
    }
  }

  openSelectMenuHandler () {
    const { x, y } = getCaretCoordinates()
    this.setState({
      selectMenuIsOpen: true,
      selectMenuPosition: { x, y }
    })
    document.addEventListener('click', this.closeSelectMenuHandler)
  }

  closeActionMenu () {
    this.setState({
      ...this.state,
      actionMenuPosition: { x: null, y: null },
      actionMenuOpen: false
    })
    document.removeEventListener('click', this.closeActionMenu, false)
  }
  openTagSelectorMenu (trigger) {
    const { x, y } = this.calculateTagSelectorMenuPosition(trigger)
    this.setState({
      ...this.state,
      selectMenuPosition: { x: x, y: y },
      selectMenuIsOpen: true
    })
    document.addEventListener('click', this.closeTagSelectorMenu, false)
  }

  closeTagSelectorMenu () {
    this.setState({
      ...this.state,
      htmlBackup: null,
      selectMenuPosition: { x: null, y: null },
      selectMenuIsOpen: false
    })
    document.removeEventListener('click', this.closeTagSelectorMenu, false)
  }

  openActionMenu (parent, trigger) {
    const { x, y } = this.calculateActionMenuPosition(parent, trigger)
    this.setState({
      ...this.state,
      actionMenuPosition: { x: x, y: y },
      actionMenuOpen: true
    })
    // Add listener asynchronously to avoid conflicts with
    // the double click of the text selection
    setTimeout(() => {
      document.addEventListener('click', this.closeActionMenu, false)
    }, 100)
  }

  handleDragHandleClick (e) {
    const dragHandle = e.target
    this.openActionMenu(dragHandle, 'DRAG_HANDLE_CLICK')
  }

  closeSelectMenuHandler () {
    this.setState({
      htmlBackup: null,
      selectMenuIsOpen: false,
      selectMenuPosition: { x: null, y: null }
    })
    document.removeEventListener('click', this.closeSelectMenuHandler)
  }

  tagSelectionHandler (tag) {
    if (tag === 'img') {
      this.setState({ ...this.state, tag: tag }, () => {
        this.closeTagSelectorMenu()
        if (this.fileInput) {
          // Open the native file picker
          this.fileInput.click()
        }
        // Add new block so that the user can continue writing
        // after adding an image
        this.props.addBlock({
          id: this.props.id,
          html: '',
          tag: 'p',
          imageUrl: '',
          ref: this.contentEditable.current
        })
      })
    } else if (tag === 'iframe') {
      this.setState({ ...this.state, tag: tag }, () => {
        console.log('iframeAdded')
        this.closeTagSelectorMenu()
        // Add new block so that the user can continue writing
        // after adding an image
        this.props.addBlock({
          id: this.props.id,
          html: '',
          tag: 'p',
          imageUrl: '',
          ref: this.contentEditable.current
        })
      })
    } else {
      // if (this.state.isTyping) {
      //   // Update the tag and restore the html backup without the command
      //   this.setState({ tag: tag, html: this.state.htmlBackup }, () => {
      //     setCaretToEnd(this.contentEditable.current);
      //     this.closeTagSelectorMenu();
      //   });
      // } else {
      this.setState({ ...this.state, tag: tag }, () => {
        this.closeTagSelectorMenu()
      })
      // }
      // this.closeTagSelectorMenu()
    }
  }

  onKeyUpHandler (e) {
    if (e.key === CMD_KEY) {
      this.openTagSelectorMenu('KEY_CMD')
    }
  }

  onKeyDownHandler (e) {
    if (e.key === '/') {
      this.setState({ htmlBackup: this.state.html })
    }
    if (e.key === 'Enter') {
      if (this.state.previousKey !== 'Shift') {
        e.preventDefault()

        this.props.addBlock({
          id: this.props.id,
          html: this.state.html,
          tag: this.state.tag,
          imageUrl: this.state.imageUrl,
          iframeUrl: this.state.iframeUrl,
          ref: this.contentEditable.current
        })
      }
    }
    if (e.key === 'Backspace' && !this.state.html) {
      e.preventDefault()
      this.props.deleteBlock({
        id: this.props.id,
        ref: this.contentEditable.current
      })
    }
    this.setState({ previousKey: e.key })
  }

  handleImageUpload (e) {
    e.preventDefault()
    if (this.fileInput.files && this.fileInput.files[0]) {
      const storageRef = firebase.storage().ref()
      const pageId = '123'
      const imageFile = this.fileInput.files[0]
      const formData = new FormData()
      formData.append('image', imageFile)
      storageRef
        .child(`images/${pageId}/${imageFile.name}`)
        .put(imageFile)
        .then(snapshot => {
          snapshot.ref.getDownloadURL().then(url => {
            this.setState({
              imageUrl: url
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  handleOnChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFocus (e) {
    this.setState({ ...this.state, isTyping: true })
  }

  handleBlur (e) {
    this.setState({ ...this.state, isTyping: false })
  }

  handleIframeSrc (e) {
    e.preventDefault()
    console.log('gotIframeUrl')
    this.setState({ iframeUrl: this.state.iframe })
  }

  onChangeHandler (e) {
    this.setState({ html: e.target.value })
  }

  renderDrager = () => {
    if (this.props.disabled) return
    else
      return (
        <span
          role='button'
          tabIndex='0'
          className='dragHandle'
          onClick={this.handleDragHandleClick}
        >
          <img src={DragHandleIcon} alt='Icon' />
        </span>
      )
  }

  renderDrag = () => {
    if (this.props.noDrag) {
      return (
        <>
          {this.state.selectMenuIsOpen && (
            <SelectMenu
              position={this.state.selectMenuPosition}
              onSelect={this.tagSelectionHandler}
              close={this.closeSelectMenuHandler}
            />
          )}
          {this.state.actionMenuOpen && (
            <ActionMenu
              position={this.state.actionMenuPosition}
              actions={{
                deleteBlock: () =>
                  this.props.deleteBlock({ id: this.props.id }),
                turnInto: () => this.openTagSelectorMenu('ACTION_MENU')
              }}
            />
          )}
          {and(
            not(equals(this.state.tag, 'img')),
            not(equals(this.state.tag, 'iframe'))
          ) && (
            <ContentEditable
              disabled={this.props.disabled}
              className='block'
              style={this.props.Boxstyle}
              data-position={this.props.position}
              data-tag={this.state.tag}
              innerRef={this.contentEditable}
              html={this.state.html}
              tagName={this.state.tag}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDownHandler}
              onKeyUp={this.onKeyUpHandler}
            />
          )}
          {this.state.tag == 'img' && (
            <ImageContent
              onChange={this.handleImageUpload}
              inputRef={ref => (this.fileInput = ref)}
              imageUrl={this.state.imageUrl}
              id={this.props.id}
              position={this.props.position}
              tag={this.state.tag}
              contentRef={this.contentEditable}
              actionMenuOpen={this.state.actionMenuOpen}
              selectMenuIsOpen={this.state.selectMenuIsOpen}
            />
          )}
          {this.state.tag == 'iframe' && (
            <IframeContent
              iframeStyle={{ height: '50vh' }}
              tag={this.state.tag}
              id={this.props.id}
              onChange={this.handleOnChange}
              onSubmit={this.handleIframeSrc}
              iframeUrl={this.state.iframeUrl}
            />
          )}
          {this.renderDrager()}
        </>
      )
    }
    return (
      <>
        {this.state.selectMenuIsOpen && (
          <SelectMenu
            position={this.state.selectMenuPosition}
            onSelect={this.tagSelectionHandler}
            close={this.closeSelectMenuHandler}
          />
        )}
        {this.state.actionMenuOpen && (
          <ActionMenu
            position={this.state.actionMenuPosition}
            actions={{
              deleteBlock: () => this.props.deleteBlock({ id: this.props.id }),
              turnInto: () => this.openTagSelectorMenu('ACTION_MENU')
            }}
          />
        )}
        <Draggable
          draggableId={this.props.id}
          index={this.props.position}
          key={this.props.id}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className='draggable'
              {...provided.draggableProps}
            >
              {and(
                not(equals(this.state.tag, 'img')),
                not(equals(this.state.tag, 'iframe'))
              ) && (
                <ContentEditable
                  className='block'
                  data-position={this.props.position}
                  data-tag={this.state.tag}
                  innerRef={this.contentEditable}
                  html={this.state.html}
                  tagName={this.state.tag}
                  onChange={this.onChangeHandler}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onKeyDown={this.onKeyDownHandler}
                  onKeyUp={this.onKeyUpHandler}
                />
              )}
              {this.state.tag == 'img' && (
                <ImageContent
                  onChange={this.handleImageUpload}
                  inputRef={ref => (this.fileInput = ref)}
                  imageUrl={this.state.imageUrl}
                  id={this.props.id}
                  position={this.props.position}
                  tag={this.state.tag}
                  contentRef={this.contentEditable}
                  actionMenuOpen={this.state.actionMenuOpen}
                  selectMenuIsOpen={this.state.selectMenuIsOpen}
                />
              )}
              {this.state.tag == 'iframe' && (
                <IframeContent
                  iframeStyle={{ height: '50vh' }}
                  tag={this.state.tag}
                  id={this.props.id}
                  onChange={this.handleOnChange}
                  onSubmit={this.handleIframeSrc}
                  iframeUrl={this.state.iframeUrl}
                />
              )}
              <span
                role='button'
                tabIndex='0'
                className='dragHandle'
                onClick={this.handleDragHandleClick}
                {...provided.dragHandleProps}
              >
                <img src={DragHandleIcon} alt='Icon' />
              </span>
            </div>
          )}
        </Draggable>
      </>
    )
  }

  render () {
    return <>{this.renderDrag()}</>
  }
}

export default EditableBlock
