import firebase from "firebase";
import _ from "lodash";

export const GET_CATEGORIES = "GET_CATEGORIES";

export const getCategories = () => {
  return dispatch => {
    firebase
      .database()
      .ref("/Categories")
      .orderByChild("name")
      .on("value", snapshot => {
        const arr = _(snapshot.val()) //wrap object so that you can chain lodash methods
          .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
          .values() //get the values of the result
          .value();

        dispatch({
          type: "GET_CATEGORIES",
          payload: arr
        });
      });
  };
};
