import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Loading from './Loading'
import { fetchUser, currentUser } from '../actions/fetchUser'
import { GoogleLoginButton } from 'react-social-login-buttons'
import firebase from 'firebase/app'

class Auth extends Component {
  componentWillMount () {
    this.props.currentUser()
  }

  login () {
    this.props.fetchUser()
  }

  loggedIn () {
    const { user, loading } = this.props.user

    if (loading) {
      return <Loading />
    } else if (user === null) {
      return (
        <div className='glogin'>
          <div className='gloginchild'>
            {' '}
            <h1>SECURED BY GOOGLE</h1>
            <GoogleLoginButton onClick={(this.login = this.login.bind(this))} />
          </div>
        </div>
      )
    } else if (user) {
      firebase
        .database()
        .ref('/users/' + user.uid)
        .update({ name: user.displayName, email: user.email })
      return <Redirect to='/create-item' />
    }
  }

  render () {
    return <div>{this.loggedIn()}</div>
  }
}

function mapStateToProps (state) {
  return { user: state.user }
}

export default connect(mapStateToProps, { fetchUser, currentUser })(Auth)
