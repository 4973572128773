import React, { Component } from "react";
import { currentUser } from "../actions/fetchUser";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase";

class BottomBar extends Component {
  componentWillMount() {
    this.props.currentUser();

    // console.log(this.props.user.logged);
  }

  state = {
    admin: null
  };
  searchAmin(user) {
    firebase
      .database()
      .ref("/users/" + user.uid)
      .once("value", snapshot => {
        const arr = snapshot.val().isAdmin;
        this.setState({ admin: arr });
      });
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  }

  renderAdminLinks() {
    switch (this.state.admin) {
      default:
        return;
      case null:
        return;

      case undefined:
        return;

      case true:
      // return <li>ADMIN LINKS</li>;
    }
  }

  renderUserData() {
    const { user, loading } = this.props.user;

    if (loading) {
      return;
    } else if (user === null) {
      return (
        <Link to="/login">
          <button type="submit" className="button-class">
            LOGIN
          </button>
        </Link>
      );
    } else if (user) {
      this.searchAmin(user);
      return (
        <div>
          <ul>
            <li className="logout">
              <button
                type="submit"
                onClick={e => this.logout()}
                className="button-class"
              >
                LOGOUT
              </button>
            </li>
            <li>
              <img className="profilepic" alt="profilepic" src={user.photoURL} />
              {this.renderAdminLinks()}
            </li>
          </ul>
        </div>
      );
    }
  }
  render() {
    return <div className="bottom-bar">{this.renderUserData()}</div>;
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(
  mapStateToProps,
  { currentUser }
)(BottomBar);
