import React, { Component } from "react";
import { connect } from "react-redux";
import { getCategories } from "../actions/getCategories";
import firebase from "firebase/app";
import "firebase/database";

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

class Forms extends Component {
  componentWillMount() {
    this.props.getCategories();
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    preview: false,
    heading: "",
    title: "",
    image: "",
    created: "Reginald Anim",
    date: " ",
    iframeSrc: "",
    category: "",
    // iframeH1: "",
    paragraph1: "",
    paragraph1Position: "",
    figure1Src: "",
    figure1Position: "",
    paragraph2: "",
    paragraph2Position: "",
    figure2Src: "",
    figure2Position: "",
    paragraph3: "",
    paragraph3Position: "",
    figure3Src: "",
    figure3Position: "",
    paragraph4: "",
    paragraph4Position: "",
    figure4Src: "",
    figure4Position: "", 
    views: 0
  };

  handleLogout(e) {
    firebase
      .auth()
      .signOut()
      .then(
        console.log(this.state.loggedIn),
        this.setState({ loggedIn: false })
      )
      .catch(err => {
        console.log(err);
      });
  }

  renderForm() {
    const { category } = this.props.categories;
    const renderCategories = category.map(category => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });
    if (!this.state.preview) {
      return (
        <div>
          <form onSubmit={this.handleSubmit}>
            <div className=" postData">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="form-group">
                      {/* <label> id</label> */}
                      {/* <input
                            className="form-control"
                            type="text"
                            name="id"
                            id="id"
                            value={this.state.id}
                            onChange={e => this.change(e)}
                          /> */}
                      <div className="form-group">
                        <label>Category</label>
                        <select
                          id="category"
                          className="form-control"
                          name="category"
                          value={this.state.category}
                          onChange={e => this.change(e)}
                        >
                          <option value="" />
                          {renderCategories}
                        </select>
                      </div>
                      <br />
                      <label> heading</label>
                      <input
                        className="form-control"
                        type="text"
                        name="heading"
                        value={this.state.heading}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>title </label>
                      <input
                        className="form-control"
                        type="text"
                        name="title"
                        value={this.state.title}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>image</label>
                      <input
                        className="form-control"
                        type="text"
                        name="image"
                        value={this.state.image}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>created</label>
                      <input
                        className="form-control"
                        type="text"
                        name="created"
                        value={this.state.created}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>date</label>
                      <input
                        className="form-control"
                        type="text"
                        name="date"
                        value={this.state.date}
                        onChange={e => this.change(e)}
                      />
                    </div>
                    <div className="form-group">
                      iframeSrc:
                      <input
                        className="form-control"
                        type="text"
                        name="iframeSrc"
                        value={this.state.iframeSrc}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      {/* iframeH1:
                          <input
                            className="form-control"
                            type="text"
                            name="iframeH1"
                            value={this.state.iframeH1}
                            onChange={e => this.change(e)}
                          /> */}
                      <br />
                      {/* paragraph1:
                           <input className="form-control"
                          type="text"
                          name="paragraph1"
                          value={this.state.paragraph1}
                          onChange={e => this.change(e)}
                        />  */}
                      <label>paragraph1</label>
                      <textarea
                        className="form-control"
                        rows={4}
                        cols={50}
                        name="paragraph1"
                        value={this.state.paragraph1}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>figure1Src</label>
                      <input
                        className="form-control"
                        type="text"
                        name="figure1Src"
                        value={this.state.figure1Src}
                        onChange={e => this.change(e)}
                      />
                      <div className="form-check-inline">
                        <label>figure1Position</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure1Position"
                          value="col-lg-6 textFigureRight"
                          onClick={e => this.change(e)}
                        />
                        Right
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure1Position"
                          value="col-lg-6 textFigureLeft"
                          onClick={e => this.change(e)}
                        />
                        Left
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure1Position"
                          value="col-lg-12 textFigureCenter"
                          onClick={e => this.change(e)}
                        />
                        Center
                      </div>
                      <br />
                      <label>paragraph2</label>
                      <textarea
                        className="form-control"
                        rows={4}
                        cols={50}
                        name="paragraph2"
                        value={this.state.paragraph2}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label> figure2Src </label>
                      <input
                        className="form-control"
                        type="text"
                        name="figure2Src"
                        value={this.state.figure2Src}
                        onChange={e => this.change(e)}
                      />
                      <div className="form-check-inline">
                        <label>figure2Position</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure2Position"
                          value="col-lg-6 textFigureRight"
                          onClick={e => this.change(e)}
                        />
                        Right
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure2Position"
                          value="col-lg-6 textFigureLeft"
                          onClick={e => this.change(e)}
                        />
                        Left
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure2Position"
                          value="col-lg-12 textFigureCenter"
                          onClick={e => this.change(e)}
                        />
                        Center
                      </div>
                      <br />
                      <label>paragraph3</label>
                      <textarea
                        className="form-control"
                        rows={4}
                        cols={50}
                        name="paragraph3"
                        value={this.state.paragraph3}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>figure3Src</label>
                      <input
                        className="form-control"
                        type="text"
                        name="figure3Src"
                        value={this.state.figure3Src}
                        onChange={e => this.change(e)}
                      />
                      <div className="form-check-inline">
                        <label>figure3Position</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure3Position"
                          value="col-lg-6 textFigureRight"
                          onClick={e => this.change(e)}
                        />
                        Right
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure3Position"
                          value="col-lg-6 textFigureLeft"
                          onClick={e => this.change(e)}
                        />
                        Left
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure3Position"
                          value="col-lg-12 textFigureCenter"
                          onClick={e => this.change(e)}
                        />
                        Center
                      </div>
                      <br />
                      <label>paragraph4</label>
                      <textarea
                        className="form-control"
                        rows={4}
                        cols={50}
                        name="paragraph4"
                        value={this.state.paragraph4}
                        onChange={e => this.change(e)}
                      />
                      <br />
                      <label>figure4Src</label>
                      <input
                        className="form-control"
                        type="text"
                        name="figure4Src"
                        value={this.state.figure4Src}
                        onChange={e => this.change(e)}
                      />
                      <div className="form-check-inline">
                        <label>figure4Position</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure4Position"
                          value="col-lg-6 textFigureRight"
                          onClick={e => this.change(e)}
                        />
                        Right
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure4Position"
                          value="col-lg-6 textFigureLeft"
                          onClick={e => this.change(e)}
                        />
                        Left
                        <input
                          className="form-check-input"
                          type="radio"
                          name="figure4Position"
                          value="col-lg-12 textFigureCenter"
                          onClick={e => this.change(e)}
                        />
                        Center
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input className="btn btn-info" type="submit" value="Preview" />
            </div>
          </form>
          <button
            className="btn btn-danger"
            onClick={e => this.handleLogout(e)}
          >
            Sign Out
          </button>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 postObject">
            <iframe
              scrolling="no"
              src={this.state.iframeSrc}
              title="iframetest"
            >
              <p>Your browser does not support iframes.</p>
            </iframe>

            <h1> {this.state.iframeH1} </h1>
          </div>
          <div className="col-lg-6 col-md-6 postObjectText">
            <button
              onClick={e => {
                e.preventDefault();
                this.setState({ preview: false });
              }}
              className="btn btn-danger"
            >
              Back
            </button>
            <button
              onClick={e => {
                this.postIt(e);
              }}
              className="btn btn-primary"
            >
              submit
            </button>
            <h1>{this.state.heading}</h1>
            <p>
              <i className="fas fa-user-secret">
                <span className="dateText">{this.state.created}</span>
              </i>
              . <br />
              <i className="far fa-calendar-alt">
                <span className="dateText">{this.state.date}</span>
              </i>
            </p>
            <div className="paragraphs container">
              <div className="row">
                <div className="col-lg-12 textContainer">
                  <div className={this.state.figure1Position}>
                    <figure id="well" className="figAlignLeft">
                      <img src={this.state.figure1Src} alt="broken" />
                    </figure>
                  </div>
                  <p>{this.state.paragraph1}</p>
                </div>
                <div className="col-lg-12 textContainer">
                  <div className={this.state.figure2Position}>
                    <figure id="well" className="figAlignLeft">
                      <img src={this.state.figure2Src} alt="broken" />
                    </figure>
                  </div>
                  <p>{this.state.paragraph2}</p>
                </div>
                <div className="col-lg-12 textContainer">
                  <div className={this.state.figure3Position}>
                    <figure id="well" className="figAlignLeft">
                      <img src={this.state.figure3Src} alt="broken" />
                    </figure>
                  </div>
                  <p>{this.state.paragraph3}</p>
                </div>
                <div className="col-lg-12 textContainer">
                  <div className={this.state.figure4Position}>
                    <figure id="well" className="figAlignLeft">
                      <img src={this.state.figure4Src} alt="broken" />
                    </figure>
                  </div>
                  <p>{this.state.paragraph4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  postIt(event) {
    event.preventDefault();
    const {
      // id,
      heading,
      title,
      image,
      created,
      date,
      iframeSrc,
      // iframeH1,
      category,
      paragraph1,
      paragraph1Position,
      figure1Src,
      figure1Position,
      paragraph2,
      paragraph2Position,
      figure2Src,
      figure2Position,
      paragraph3,
      paragraph3Position,
      figure3Src,
      figure3Position,
      paragraph4,
      paragraph4Position,
      figure4Src,
      figure4Position,
      views
    } = this.state;

    const post = {
      info: {
        // id,
        heading,
        title,
        image,
        created,
        date
      },
      iframeSrc,
      // iframeH1,
      paragraph1,
      paragraph1Position,
      figure1Src,
      figure1Position,
      category,
      paragraph2,
      paragraph2Position,
      figure2Src,
      figure2Position,
      paragraph3,
      paragraph3Position,
      figure3Src,
      figure3Position,
      paragraph4,
      paragraph4Position,
      figure4Src,
      figure4Position,
      views
    };

    firebase
      .database()
      .ref("post")
      .push(post);
    this.setState({ preview: false });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ preview: true });

    // firebase
    //   .database()
    //   .ref("post")
    //   .push(post);
  }

  change = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

export default connect(
  mapStateToProps,
  { getCategories }
)(Forms);
