import React, { Component } from "react";
// import Loader from "./Loader";
import { Link } from "react-router-dom";

class Navbar extends Component {
  componentDidMount() {
    var roundbutton = document.querySelector("#roundButton");
    roundbutton.addEventListener("click", showMenu, false);

    var popOutMenu = document.querySelector("#popOutMenu");
    popOutMenu.addEventListener("click", hideMenu, false);

    function showMenu(e) {
      popOutMenu.classList.add("show");

      document.body.style.overflow = "hidden";
    }

    function hideMenu(e) {
      popOutMenu.classList.remove("show");
      e.stopPropagation();

      document.body.style.overflow = "auto";
    }
  }

  render() {
    return (
      
      <div>
        {/* <Loader /> */}
        <div id="popOutMenu">
          <ul className="nav-ul">
            {/* links  */}
            <div className="popUpLinks">
              <li className="nav-item hvr-sweep-to-right">
                <Link to="/">Home.</Link>
              </li>
              <li className="nav-item2 hvr-sweep-to-right">
                <Link to="/about">About.</Link>
              </li>
              <li className="nav-item3 hvr-sweep-to-right">
                <Link to="/">Contact.</Link>
              </li>
            </div>
            <p>
              <br />
              Designed by <br /> Reggie <br /><br />

            </p>
          </ul>
          <div className="disclaimer">
            <p> Click on menu to close</p>
          </div>
        </div>

        {/* <!--End of Pop Out Menu--> */}
        {/* nav-menu fixed top */}
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-sm-12 nav-menuBoss">
                <button id="roundButton" />
                <a href="http://www.facebook.com/reggie.anim">
                  <i className="fab fa-facebook fa-lg" />
                </a>
                <a href="https://www.instagram.com/animreggie/">
                  <i className="fab fa-instagram fa-lg" />
                </a>
                <a href="https://twitter.com/baddaz_stuff">
                  <i className="fab fa-twitter fa-lg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
