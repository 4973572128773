import { combineReducers } from 'redux'
import CategoryReducer from './reducer-getCategories'
import UserReducer from './reducer-fetchUser'
import Postreducer from './reducer-posts'
import DraftReducer from './reducer-drafts'
export default combineReducers({
  posts: Postreducer,
  drafts: DraftReducer,
  user: UserReducer,
  categories: CategoryReducer
})
