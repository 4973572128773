import React from 'react'

const ImageContent = ({
  position,
  tag,
  contentRef,
  actionMenuOpen,
  selectMenuIsOpen,
  id,
  onChange,
  inputRef,
  imageUrl
}) => {
  return (
    <div
      data-position={position}
      data-tag={tag}
      ref={contentRef}
      className={[
        'image',
        actionMenuOpen || selectMenuIsOpen ? 'blockSelected' : null
      ].join(' ')}
    >
      <input
        id={`${id}_fileInput`}
        name={tag}
        type='file'
        onChange={onChange}
        ref={inputRef}
        hidden
      />
      {!imageUrl && (
        <label htmlFor={`${id}_fileInput`} className='fileInputLabel'>
          No Image Selected. Click To Select.
        </label>
      )}
      {imageUrl && <img src={imageUrl} alt={'broken'} />}
    </div>
  )
}

export default ImageContent
