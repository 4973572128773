import React, { Component } from "react";
import { BounceLoader } from "react-spinners";

class Loading extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="Loading">
              <BounceLoader size={100} color={"#80DEEA"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
