import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/database'

export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCH_DRAFTS = 'FETCH_DRAFTS'
export const FETCH_DRAFT = 'FETCH_DRAFT'
export const FETCH_POST = 'FETCH_POST'

export const fetchposts = () => {
  return dispatch => {
    firebase
      .database()
      .ref()
      .child('post')
      .orderByKey()
      .once('value', snapshot => {
        const arr1 = _(snapshot.val()) //wrap object so that you can chain lodash methods
          .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
          .values() //get the values of the result
          .value()

        const arr = arr1.reverse()
        dispatch({
          type: FETCH_POSTS,
          payload: arr
        })
      })
  }
}

export const fetchDrafts = () => {
  return dispatch => {
    firebase
      .database()
      .ref()
      .child('draft')
      .orderByKey()
      .once('value', snapshot => {
        const arr1 = _(snapshot.val()) //wrap object so that you can chain lodash methods
          .mapValues((value, id) => _.merge({}, value, { id })) //attach id to object
          .values() //get the values of the result
          .value()

        const arr = arr1.reverse()
        dispatch({
          type: FETCH_DRAFTS,
          payload: arr
        })
      })
  }
}
export const fetchpost = id => {
  return dispatch => {
    firebase
      .database()
      .ref()
      .child(`post/${id}`)
      .on('value', snapshot => {
        // console.log(viewcounter)
        const arr = snapshot.val() //wrap object so that you can chain lodash methods
        dispatch({
          type: FETCH_POST,
          payload: arr
        })
        //   firebase
        // .database()
        // .ref(`post/${id}/`)
        // .update({views: viewcounter})
      })
  }
}

export const fetchDraft = id => {
  return dispatch => {
    firebase
      .database()
      .ref()
      .child(`draft/${id}`)
      .on('value', snapshot => {
        // console.log(viewcounter)
        const arr = snapshot.val() //wrap object so that you can chain lodash methods
        dispatch({
          type: FETCH_DRAFT,
          payload: arr
        })
        //   firebase
        // .database()
        // .ref(`post/${id}/`)
        // .update({views: viewcounter})
      })
  }
}
